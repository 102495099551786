import { cn } from 'common/utils';
import React from 'react';

export const SyncOff = ({ size = 24, color = '#084D6E', onClick }) => (
  <svg
    className={cn({ 'cursor-pointer': onClick })}
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.9289 3.94979C16.2696 2.72469 14.2175 2 11.9956 2C8.05908 2 4.7356 4.27276 3.10449 7.55497C2.85871 8.04955 3.0604 8.64973 3.55498 8.89552C4.04956 9.1413 4.64974 8.93961 4.89552 8.44503C6.20981 5.80034 8.86464 4 11.9956 4C13.6634 4 15.2125 4.51029 16.4947 5.38394L17.9289 3.94979ZM18.6152 7.50608C18.8187 7.80514 19.0024 8.11875 19.1645 8.44503C19.4103 8.93961 20.0105 9.1413 20.5051 8.89552C20.9997 8.64973 21.2014 8.04955 20.9556 7.55497C20.6962 7.03313 20.3926 6.53709 20.0495 6.07179L18.6152 7.50608Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.13116 20.0505C7.7905 21.2756 9.84256 22.0002 12.0645 22.0002C16.001 22.0002 19.3245 19.7275 20.9556 16.4453C21.2014 15.9507 20.9997 15.3505 20.5051 15.1047C20.0105 14.8589 19.4103 15.0606 19.1645 15.5552C17.8502 18.1999 15.1954 20.0002 12.0645 20.0002C10.3967 20.0002 8.84761 19.49 7.56531 18.6163L6.13116 20.0505ZM5.44482 16.4942C5.24139 16.1951 5.05767 15.8815 4.89552 15.5552C4.64973 15.0606 4.04955 14.8589 3.55497 15.1047C3.06039 15.3505 2.8587 15.9507 3.10449 16.4453C3.36382 16.9671 3.66747 17.4632 4.01052 17.9285L5.44482 16.4942Z'
      fill={color}
    />
    <path d='M4 4V8' stroke={color} strokeWidth='2' strokeLinecap='round' />
    <path
      d='M7.39015 8H4.00006'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
    />
    <path
      d='M20.0601 20V16'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
    />
    <path
      d='M16.6699 16H20.06'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
    />
    <path
      d='M3.28003 20.75L20.78 3.25'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);

export const SyncOn = ({ size = 24, color = '#084D6E', onClick }) => (
  <svg
    className={cn({ 'cursor-pointer': onClick })}
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    onClick={onClick}
  >
    <path
      d='M20.06 8C18.5873 5.03655 15.5293 3 11.9956 3C8.46185 3 5.4727 5.03655 4 8'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
    />
    <path d='M4 4V8' stroke={color} strokeWidth='2' strokeLinecap='round' />
    <path
      d='M7.39015 8H4.00006'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
    />
    <path
      d='M4 16C5.4727 18.9634 8.53075 21 12.0645 21C15.5982 21 18.5873 18.9634 20.06 16'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
    />
    <path
      d='M20.0601 20V16'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
    />
    <path
      d='M16.6699 16H20.06'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
    />
  </svg>
);
