import Brain from 'assets/icons/Brain';
import Facebook from 'assets/icons/Facebook';
import Gravatar from 'assets/icons/Gravatar';
import Instagram from 'assets/icons/Instagram';
import Link from 'assets/icons/Link';
import LinkedIn from 'assets/icons/LinkedIn';
import Mail from 'assets/icons/Mail';
import Twitter from 'assets/icons/Twitter';
import Whatsapp from 'assets/icons/Whatsapp';
import Divider from 'common/components/Divider';
import useTranslation from 'common/hooks/useTranslation';
import { getDateLocale } from 'common/utils';
import { formatDistanceToNow } from 'date-fns';
import _, { isEmpty } from 'lodash';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { ShowFeature } from 'split';

import EducationSection from './EducationSection';
import ExperienceSection from './ExperienceSection';

const CandidateGeneralView = ({
  submission: {
    ai_info,
    summary,
    candidate,
    email,
    phone: phoneNumber,
    social_networks: socialNetworks,
    experience_entries,
    experience,
    education_entries,
    education,
  },
}) => {
  const { t, i18n } = useTranslation('offers\\details');

  const birthdate = candidate?.birthdate;
  const age = ai_info?.age;

  const getSocialNetworkIcon = (social) => {
    switch (social) {
      case 'linkedin':
        return <LinkedIn size='16px' color='#8DAEB5' />;
      case 'facebook':
        return <Facebook size='16px' color='#8DAEB5' />;
      case 'instagram':
        return <Instagram size='16px' color='#8DAEB5' />;
      case 'twitter':
        return <Twitter size='16px' color='#8DAEB5' />;
      case 'gravatar':
        return <Gravatar size='16px' color='#8DAEB5' />;
      default:
        return <Link size='16px' color='#8DAEB5' />;
    }
  };

  const [isExpanded, setIsExpanded] = useState(false); // State to manage the text expansion
  const getAIGeneratedSummaryToggle = () => {
    // Function to toggle the expanded state
    const toggleReadMore = () => {
      setIsExpanded(!isExpanded);
    };

    return (
      <div className='flex flex-col items-center'>
        <div
          className={`text-dark-charcoal text-sm ${
            !isExpanded ? 'line-clamp-5' : ''
          }`}
          onClick={toggleReadMore}
        >
          {ai_info?.ranking_questions_overview ? (
            <ReactMarkdown>{ai_info?.ranking_questions_overview}</ReactMarkdown>
          ) : (
            <ReactMarkdown>{ai_info.summary}</ReactMarkdown>
          )}
        </div>
        {/* Toggle button */}
        <button
          className='text-vibrant-orange text-xs mt-2'
          onClick={toggleReadMore}
        >
          {isExpanded ? t('read-less') : t('read-more')}
        </button>
      </div>
    );
  };

  return (
    <div className='w-full h-full bg-ghost-white p-2.5 flex flex-col flex-grow'>
      <div className='w-full bg-white p-5 flex flex-col flex-grow'>
        {ai_info?.summary ? (
          <>
            <div className='bg-vibrant-orange/20 w-full flex flex-col py-4 px-2 gap-2'>
              <h4 className='text-vibrant-orange font-semibold uppercase'>
                {t('ai-ranking')}
              </h4>
              {getAIGeneratedSummaryToggle()}
              <ShowFeature featureFlag='SUBMISSION_AI_INFO_EXTRA'>
                <span>
                  <strong className='text-dark-charcoal text-sm'>
                    {t('age')}
                  </strong>
                  : {age || 'N/A'}
                </span>
              </ShowFeature>
              <div className='flex items-center'>
                <Brain size='16px' color='#7A7A7A' />
                <p className='text-sonic-silver text-xs ml-2'>
                  {' '}
                  {t('ai-generated-message')} {t('last-update')}{' '}
                  {formatDistanceToNow(new Date(ai_info?.updated_at), {
                    locale: getDateLocale(i18n.language),
                    addSuffix: true,
                  })}
                  .
                </p>
              </div>
            </div>
            <Divider />
          </>
        ) : null}

        <div className='w-full flex flex-col py-4 px-2 gap-2'>
          <h4 className='text-dark-liver font-semibold uppercase'>
            {t('summary')}
          </h4>
          <p className='text-dark-charcoal text-sm'>{summary || 'N/A'}</p>
        </div>
        <Divider />
        <div className='w-full flex'>
          <div className='w-1/3 flex flex-col py-4 px-2 gap-2'>
            <h4 className='text-dark-liver font-semibold uppercase'>
              {t('birthdate')}
            </h4>
            <p className='text-dark-charcoal text-sm'>{birthdate || 'N/A'}</p>
          </div>
          <div className='w-1/3 flex flex-col py-4 px-2 gap-2'>
            <h4 className='text-dark-liver font-semibold uppercase'>
              {t('email')}
            </h4>
            <div className='flex items-center gap-2  w-[200px]'>
              <div className='h-16px'>
                <Mail size='16px' color='#8DAEB5' />
              </div>
              <a
                href={`mailto:${email}`}
                className='truncate text-dark-charcoal text-sm hover:underline'
                title={email || 'N/A'}
              >
                {email || 'N/A'}
              </a>
            </div>
          </div>
          <div className='w-1/3 flex flex-col py-4 px-2 gap-2'>
            <h4 className='text-dark-liver font-semibold uppercase'>
              {t('phone-number')}
            </h4>
            <div className='flex items-center gap-2 w-[200px]'>
              <div className='h-16px'>
                <Whatsapp size='16px' color='#8DAEB5' />
              </div>
              <a
                href={`https://wa.me/${phoneNumber}`}
                target='_blank'
                rel='noreferrer'
                className='truncate text-dark-charcoal text-sm hover:underline'
              >
                {phoneNumber || 'N/A'}
              </a>
            </div>
          </div>
        </div>
        <Divider />
        {!Object.values(socialNetworks).every(_.isEmpty) && (
          <React.Fragment>
            <div className='w-full flex flex-col py-4 px-2 gap-2 text-dark-charcoal'>
              <h4 className='text-dark-liver font-semibold uppercase'>
                {t('social-networks')}
              </h4>
              <div className='w-full grid grid-cols-2 gap-y-2'>
                {Object.keys(socialNetworks)
                  .filter((social) => !isEmpty(socialNetworks[social]))
                  .map((social, index) => (
                    <div key={index} className='flex items-center gap-2'>
                      {getSocialNetworkIcon(social)}
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href={socialNetworks[social]}
                        className='text-dark-charcoal text-sm hover:underline truncate'
                      >
                        {socialNetworks[social]}
                      </a>
                    </div>
                  ))}
              </div>
            </div>
            <Divider />
          </React.Fragment>
        )}
        <div className='w-full flex flex-col py-3 px-2 gap-4'>
          <ExperienceSection
            experienceEntries={experience_entries}
            experience={experience}
          />
        </div>
        <Divider />

        <div className='w-full flex flex-col py-3 px-2 gap-4'>
          <EducationSection
            educationEntries={education_entries}
            education={education}
          />
        </div>
      </div>
    </div>
  );
};

export default CandidateGeneralView;
