import { cva } from 'class-variance-authority';
import { cn } from 'common/utils';
import React, { useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

const Drawer = ({
  side,
  size,
  children,
  isOpen,
  setIsOpen,
  outsideClickable = true,
  handleClose = null,
}) => {
  const drawerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (event.target.closest('.toast-container')) {
      return;
    }

    if (outsideClickable && isOpen) {
      setIsOpen(false);
      if (handleClose) {
        handleClose();
      }
    }
  };

  useOnClickOutside(drawerRef, handleClickOutside);

  const drawerVariants = cva(
    [
      'absolute w-screen h-full shadow bg-white overflow-y-auto',
      'transform transition-all ease-in-out duration-200',
      { 'translate-x-0': isOpen, 'translate-x-full': !isOpen },
    ],
    {
      variants: {
        side: {
          right: 'right-0',
          left: 'left-0',
        },
        size: {
          small: 'max-w-sm',
          medium: 'max-w-lg',
          large: 'max-w-3xl',
        },
      },
      defaultVariants: {
        side: 'right',
        size: 'medium',
      },
    },
  );

  return (
    <div
      className={cn(
        'fixed overflow-hidden z-40 bg-dark-charcoal bg-opacity-25 inset-0',
        'transform transition-opacity ease-in-out duration-200',
        {
          'opacity-100 translate-x-0': isOpen,
          'opacity-0 translate-x-full': !isOpen,
        },
      )}
    >
      <div ref={drawerRef} className={cn(drawerVariants({ side, size }))}>
        {children}
      </div>
    </div>
  );
};

export default Drawer;
