import Button from 'common/components/Button';
import { PlaceholderEmptyState } from 'common/components/PlaceholderEmptyState';
import { cn } from 'common/utils';
import DOMPurify from 'dompurify';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DetailsTab = ({ offer, setActiveTab }) => {
  const { t } = useTranslation('applications\\index');

  return (
    <div className='grid grid-cols-1 gap-4'>
      {offer?.description ? (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              offer.description.replace(/\n/g, '<br />'),
            ),
          }}
          className={cn('p-4', 'text-dark-liver text-sm text-justify')}
        />
      ) : (
        <PlaceholderEmptyState className='pt-4' text={t('noDescription')} />
      )}
      <div className='flex justify-center px-4'>
        <Button
          onClick={() => {
            setActiveTab('application');
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          {t('candidateOffer')}
        </Button>
      </div>
    </div>
  );
};

export default DetailsTab;
