import Bookmark from 'assets/icons/Bookmark';
import EditProcess from 'assets/icons/EditProcess';
import FileEditingOne from 'assets/icons/FileEditingOne';
import Help from 'assets/icons/Help';
import Ranking from 'assets/icons/Ranking';
import SAP from 'assets/icons/SAP';
import Workable from 'assets/icons/Workable';
import { Logo } from 'assets/logos';
import Avatar from 'common/components/Avatar';
import SourceTooltip from 'common/components/SourceTooltip';
import useTranslation from 'common/hooks/useTranslation';
import { cn, formatCandidateName } from 'common/utils';
import React from 'react';
import { ShowFeature } from 'split';

import AIClassification from './AIClassification';

function getClassificationColor(classification, min, max) {
  if (classification >= max) {
    return 'bg-green-100 text-green-500';
  } else if (classification < max && classification >= min) {
    return 'bg-yellow-100 text-yellow-500';
  } else {
    return 'bg-red-100 text-red-500';
  }
}

function renderAvgAssessment(avgAssessment) {
  const cn_colors = getClassificationColor(avgAssessment, 50, 80);

  return (
    <div
      className={cn(
        'px-2 py-1 mb-1 text-2xl font-semibold rounded flex justify-center',
        cn_colors,
      )}
    >
      <div className='flex items-center mr-3'>
        <FileEditingOne size='20px' color='currentColor' />
      </div>
      <div className='flex items-baseline'>
        <span className='font-semibold'>{avgAssessment}%</span>
      </div>
    </div>
  );
}

const CandidateHeader = ({ offer, submission, isLoading }) => {
  const { t } = useTranslation('offers\\details');

  const rawSourceName = submission?.source_name || '';

  const transformSourceName = (source) => {
    if (!source) return '';
    const lower = source.toLowerCase();
    return lower === 'validate_api' ? 'validate' : lower;
  };

  const capitalize = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const displaySourceName = capitalize(transformSourceName(rawSourceName));

  const getSourceIcon = () => {
    const s = rawSourceName || '';
    switch (s) {
      case 'SAPSF':
        return <SAP size='24' />;
      case 'VALIDATE_API':
        return <Logo size='24' />;
      case 'WORKABLE':
        return <Workable size='24' />;
      default:
        return null;
    }
  };

  const getAvatarBorderColor = () => {
    const s = rawSourceName || '';
    switch (s) {
      case 'SAPSF':
        return 'border-dark-blue';
      case 'VALIDATE_API':
        return 'border-vibrant-orange';
      case 'WORKABLE':
        return 'border-gray-300';
      default:
        return 'border-gray-300';
    }
  };

  const sourceIcon = getSourceIcon();

  const getCurrentPhase = (phase) => {
    switch (phase) {
      case 'all':
      case 'candidates':
        return `1/4 ${t('phases')}`;
      case 'evaluate':
      case 'evaluation':
        return `2/4 ${t('phases')}`;
      case 'rank':
      case 'classification':
        return `3/4 ${t('phases')}`;
      case 'interview':
        return `4/4 ${t('phases')}`;
      default:
        return null;
    }
  };

  const {
    candidate,
    headline,
    location,
    avg_assessment: avgAssessment,
    phase,
    is_favorite: isFavorite,
  } = submission || {};

  const candidateName = candidate && formatCandidateName(candidate, true);

  let aiBaseClassification,
    aiBaseClassificationWithAssessments,
    aiBaseClassificationWithQuestions,
    aiRankingQuestions,
    aiWeightedRanking,
    aiWeightedRankingPercentage;

  if (submission && submission.ai_info !== null) {
    ({
      classification: aiBaseClassification,
      classification_with_assessments: aiBaseClassificationWithAssessments,
      classification_with_questions: aiBaseClassificationWithQuestions,
      ranking_questions: aiRankingQuestions,
      weighted_ranking: aiWeightedRanking,
      weighted_ranking_percentage: aiWeightedRankingPercentage,
    } = submission.ai_info);
  } else {
    aiBaseClassification = null;
    aiBaseClassificationWithAssessments = null;
    aiBaseClassificationWithQuestions = null;
    aiRankingQuestions = null;
    aiWeightedRanking = null;
  }

  let asPercentage = false;
  if (
    offer &&
    offer.external_source &&
    offer.external_source['name'] === 'SAPSF'
  ) {
    aiWeightedRanking = aiWeightedRankingPercentage
      ? aiWeightedRankingPercentage.toFixed(0)
      : null;
    asPercentage = true;
  }

  let icon_ai_assessments = <FileEditingOne size='20px' color='currentColor' />;
  let icon_ai_assessments_questions = (
    <>
      <FileEditingOne size='20px' color='currentColor' />{' '}
      <Help size='20px' color='currentColor' />
    </>
  );
  let icon_ai_questions = <Help size='20px' color='currentColor' />;
  let icon_aiWeightedRanking = <Ranking size='20px' color='currentColor' />;

  function formatClassification(value) {
    if (value == null) return null;
    return Math.floor(value * 10) / 10;
  }

  return (
    <>
      {isLoading ? (
        <div className='flex items-center gap-6 animate-pulse w-full'>
          <div className='w-[88px] h-[88px] bg-bright-grey rounded-full' />
          <div className='flex-1 flex flex-col gap-1.5'>
            <div className='h-[34px] w-64 bg-bright-grey rounded' />
            <div className='h-[20px] w-56 bg-bright-grey rounded' />
            <div className='h-[18px] w-48 bg-bright-grey rounded' />
          </div>
          <div className='flex flex-col items-end'>
            <div className='h-[40px] w-[60px] mb-1 bg-bright-grey rounded' />
            <div className='h-[20px] w-[80px] mb-2 bg-bright-grey rounded' />
            <div className='h-[20px] w-[90px] bg-bright-grey rounded' />
          </div>
        </div>
      ) : (
        <div className='flex items-center gap-6 w-full'>
          <div className='relative inline-block'>
            <Avatar
              className={cn('border-2 rounded-full', getAvatarBorderColor())}
              size='profile'
              name={candidateName || 'John Doe'}
            />
            {sourceIcon && (
              <div className='absolute bottom-0 right-0'>
                <SourceTooltip
                  content={`${t('tooltip.sent_by')} ${displaySourceName}`}
                >
                  <div
                    className={cn(
                      'w-8 h-8 rounded-full bg-white border-2 flex items-center justify-center',
                      getAvatarBorderColor(),
                    )}
                  >
                    {sourceIcon}
                  </div>
                </SourceTooltip>
              </div>
            )}
          </div>

          <div className='flex-1 flex flex-col gap-1.5 text-dark-liver'>
            <div className='flex items-center justify-start gap-1'>
              <h1 className='font-semibold text-[28px]/[34px] max-w-xs'>
                {candidateName}
              </h1>
              <div className='ml-2'>
                {isFavorite ? <Bookmark color='#F16347' size='28' /> : null}
              </div>
            </div>
            {headline && (
              <h4 className='text-base/[20px] font-semibold'>{headline}</h4>
            )}
            {location && (
              <p className='text-dark-charcoal text-sm/[18px]'>{location}</p>
            )}
          </div>
          <div className='flex'>
            <div className='flex flex-col items-end'>
              {avgAssessment && aiBaseClassification ? (
                <AIClassification
                  classification={formatClassification(aiWeightedRanking)}
                  asPercentage={asPercentage}
                />
              ) : null}
              <ShowFeature featureFlag='SUBMISSION_AI_INFO_EXTRA'>
                {aiBaseClassification ? (
                  <AIClassification
                    classification={aiBaseClassification}
                    asPercentage={asPercentage}
                  />
                ) : null}
                <div className='flex flex-col items-end'>
                  {aiBaseClassification &&
                  aiBaseClassificationWithAssessments ? (
                    <AIClassification
                      classification={aiBaseClassificationWithAssessments}
                      icon={icon_ai_assessments}
                      asPercentage={asPercentage}
                    />
                  ) : null}
                </div>
                <div className='flex flex-col items-end'>
                  {aiBaseClassification && aiBaseClassificationWithQuestions ? (
                    <AIClassification
                      classification={aiBaseClassificationWithQuestions}
                      icon={icon_ai_assessments_questions}
                      asPercentage={asPercentage}
                    />
                  ) : null}
                </div>
                <div className='flex flex-col items-end'>
                  {aiBaseClassification && aiRankingQuestions ? (
                    <AIClassification
                      classification={aiRankingQuestions}
                      icon={icon_ai_questions}
                      asPercentage={asPercentage}
                    />
                  ) : null}
                </div>
                <div className='flex flex-col items-end'>
                  {aiBaseClassification && aiWeightedRanking ? (
                    <AIClassification
                      classification={aiWeightedRanking}
                      icon={icon_aiWeightedRanking}
                      asPercentage={asPercentage}
                    />
                  ) : null}
                </div>
              </ShowFeature>
            </div>
            <div className='flex flex-col items-end pl-2'>
              {avgAssessment > 0 ? (
                renderAvgAssessment(avgAssessment)
              ) : aiWeightedRanking ? (
                <AIClassification
                  classification={formatClassification(aiWeightedRanking)}
                  asPercentage={asPercentage}
                />
              ) : null}
              {(avgAssessment > 0 || aiWeightedRanking) && (
                <p className='text-sm text-sonic-silver mb-2'>
                  {t('classification')}
                </p>
              )}
              <div className='flex items-center gap-1.5'>
                <EditProcess size='20px' color='#7A7A7A' />
                <p className='text-sm text-sonic-silver'>
                  {getCurrentPhase(phase)}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CandidateHeader;
