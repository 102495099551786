import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import LabelledInput from 'common/components/LabelledInput';
import LabelledPhoneInput from 'common/components/LabelledPhoneInput';
import Loader from 'common/components/Loader';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import {
  selectCurrentCompany,
  updateCurrentCompany,
} from 'common/slices/auth.slice';
import {
  useGetCompanyDetailQuery,
  useUpdateCompanyMutation,
} from 'common/slices/companyApi.slice';
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import LabelledSocialInput from '../../common/components/LabelledSocialInput';

const DataSettingsForm = () => {
  const toast = useToast();
  const dispatch = useDispatch();

  const { t } = useTranslation('settings\\index');

  const currentCompany = useSelector(selectCurrentCompany);

  const { data: company, isLoading: isLoadingCompany } =
    useGetCompanyDetailQuery({ slug: currentCompany.slug });

  const [updateCompany, { isLoading: isUpdatingCompany }] =
    useUpdateCompanyMutation();

  const initialValues = {
    name: company?.name ?? '',
    webpage: company?.webpage ?? '',
    location: company?.location ?? '',
    sector: company?.sector ?? '',
    n_employees: company?.n_employees ?? '',
    email: company?.email ?? '',
    phone: company?.phone ?? '',
    instagram: company?.instagram ?? '',
    linkedin: company?.linkedin ?? '',
    facebook: company?.facebook ?? '',
    twitter: company?.twitter ?? '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t('errors.required-company-name')),
    webpage: Yup.string().url(t('errors.invalid-url')).notRequired(),
    location: Yup.string().notRequired(),
    sector: Yup.string().notRequired(),
    n_employees: Yup.number()
      .integer(t('errors.employees-must-be-integer'))
      .notRequired(),
    email: Yup.string().email(t('errors.invalid-company-email')).notRequired(),
    phone: Yup.string().notRequired(),
    instagram: Yup.string().notRequired(),
    linkedin: Yup.string().notRequired(),
    facebook: Yup.string().notRequired(),
    twitter: Yup.string().notRequired(),
  });

  const handleSubmit = async (values) => {
    const data = {
      name: values.name,
      webpage: values.webpage,
      location: values.location,
      sector: values.sector,
      n_employees: values.n_employees,
      email: values.email,
      phone: values.phone,
      instagram: values.instagram,
      linkedin: values.linkedin,
      facebook: values.facebook,
      twitter: values.twitter,
    };

    try {
      const res = await updateCompany({
        companyId: currentCompany.id,
        data: data,
      });

      await dispatch(updateCurrentCompany(res.data));

      toast.newToast('positive', t('toasts.success'));
    } catch (err) {
      switch (err?.status) {
        case 403:
          toast.newToast('negative', t('toasts.forbidden'));
          break;
        default:
          toast.newToast('negative', t('toasts.something-wrong'));
      }
    }
  };

  return (
    <>
      {isLoadingCompany ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form
              id='company-data-form'
              className='grid grid-cols-2 gap-8 mt-6'
            >
              <div className='flex flex-col gap-6'>
                <LabelledInput
                  name='name'
                  label={t('companyName')}
                  type='text'
                  className='w-full p-3 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200'
                />
                <LabelledInput
                  name='webpage'
                  label={t('companyWebPage')}
                  type='text'
                  className='w-full p-3 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200'
                />
                <LabelledInput
                  name='location'
                  label={t('companyLocation')}
                  type='text'
                  className='w-full p-3 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200'
                />
                <LabelledInput
                  name='sector'
                  label={t('companySector')}
                  type='text'
                  className='w-full p-3 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200'
                />
                <LabelledInput
                  name='n_employees'
                  label={t('numberOfEmployees')}
                  type='text'
                  className='w-full p-3 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200'
                />
                <LabelledInput
                  name='email'
                  label={t('companyEmail')}
                  type='email'
                  className='w-full p-3 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200'
                />
              </div>

              <div className='flex flex-col gap-6'>
                <LabelledPhoneInput
                  name='phone'
                  label={t('phoneNumber')}
                  type='tel'
                  className='w-full p-3 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200'
                />
                <LabelledSocialInput
                  name='linkedin'
                  label={t('LinkedIn')}
                  platform='linkedin'
                  type='text'
                  className='w-full pl-10 p-3 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200'
                />

                <LabelledSocialInput
                  name='instagram'
                  label={t('Instagram')}
                  platform='instagram'
                  type='text'
                  className='w-full pl-10 p-3 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200'
                />
                <LabelledSocialInput
                  name='twitter'
                  label={t('Twitter')}
                  platform='twitter'
                  type='text'
                  className='w-full pl-10 p-3 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200'
                />
                <LabelledSocialInput
                  name='facebook'
                  label={t('Facebook')}
                  platform='facebook'
                  type='text'
                  className='w-full pl-10 p-3 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200'
                />
              </div>

              <div className='w-1/4'>
                <Button
                  type='submit'
                  variant='primary'
                  form='company-data-form'
                >
                  {t('save')}
                  <Right size='16px' color='#FFFFFF' />
                </Button>
              </div>
            </Form>
          </Formik>
          {isUpdatingCompany && <Loader />}
        </>
      )}
    </>
  );
};

export default DataSettingsForm;
