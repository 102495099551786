import React from 'react';

import Toast from './Toast';

const ToastContainer = ({ toasts }) => {
  return (
    <div className='fixed z-50 top-8 -right-[200px] flex flex-col gap-2 toast-container'>
      {toasts.map((toast) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </div>
  );
};

export default ToastContainer;
