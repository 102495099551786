import Brain from 'assets/icons/Brain';
import { Copy } from 'assets/icons/Copy';
import Download from 'assets/icons/Download';
import Left from 'assets/icons/Left';
import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import Divider from 'common/components/Divider';
import Loader from 'common/components/Loader';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import insightsTracker from 'common/insights_tracker';
import { selectCurrentCompany, selectUser } from 'common/slices/auth.slice';
import { useGetCurrentCompanySettingsQuery } from 'common/slices/companyApi.slice';
import {
  useGetSubmissionCurriculumQuery,
  useGetSubmissionQuery,
  useGetSubmissionQuestionsQuery,
  useGetSubmissionTestsQuery,
  useUpdateSubmissionAIInfoMutation,
} from 'common/slices/submissionApi.slice';
import { cn } from 'common/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import CandidateCurriculum from './CandidateCurriculum';
import CandidateEvaluation from './CandidateEvaluation';
import CandidateGeneralView from './CandidateGeneralView';
import CandidateHeader from './CandidateHeader';
import CandidateNotes from './CandidateNotes';
import CandidateQuestions from './CandidateQuestions';
import OptionsMenu from './OptionsMenu';

const CandidateDrawer = ({
  submissionSlug,
  isOpen,
  closeDrawer,
  candidates,
  setCurrentSubmissionSlug,
  offer,
}) => {
  const { companySlug } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation('offers\\details');
  const currentUser = useSelector(selectUser);
  const currentCompany = useSelector(selectCurrentCompany);
  const [tab, setTab] = useState('general');
  const [submission, setSubmission] = useState(null);
  const [notesSeparatorLength, setNotesSeparatorLength] = useState(0);
  const [updateSubmissionAIInfo] = useUpdateSubmissionAIInfoMutation();

  const hasAccessToViewApplication =
    currentUser?.role?.name === 'staff' || currentUser?.role?.name === 'admin';

  useEffect(() => {
    if (isOpen && submission) {
      insightsTracker.submissionDetailPageView(submission.id);
    }
  }, [isOpen, submission]);

  const handleAIReview = async () => {
    try {
      await updateSubmissionAIInfo({ submissionSlug }).unwrap();
      toast.newToast('positive', t('toasts.success-ai-review'));
    } catch (err) {
      switch (err?.status) {
        case 403:
          toast.newToast('negative', t('toasts.forbidden'));
          break;
        default:
          toast.newToast('negative', t('toasts.something-wrong'));
      }
    }
  };

  const { data: initialSubmission, isFetching: isLoadingSubmission } =
    useGetSubmissionQuery({ submissionSlug });

  const { data: companySettings, isLoadingCompanySettings } =
    useGetCurrentCompanySettingsQuery({
      companyId: currentCompany.id,
    });

  useEffect(() => {
    if (!isLoadingSubmission) {
      setSubmission(initialSubmission);
    }
  }, [initialSubmission, isLoadingSubmission]);

  useEffect(() => {
    if (!isLoadingSubmission) {
      setNotesSeparatorLength(submission?.notes?.length || 0);
    }
  }, [submission, isLoadingSubmission]);

  const navigateToSubmissionApplication = () => {
    if (submission && submission.external_slug) {
      const targetUrl = `/applications/s/${submission.external_slug}`;
      const fullUrl = window.location.origin + targetUrl;
      navigator.clipboard.writeText(fullUrl).then(() => {});
      window.open(fullUrl, '_blank');
    } else {
      toast.newToast('negative', t('toasts.external-slug-missing'));
    }
  };

  const {
    data: cvUrl,
    error,
    isFetching: isLoadingCurriculum,
  } = useGetSubmissionCurriculumQuery({ submissionSlug });
  const { data: tests, isFetching: isLoadingTests } =
    useGetSubmissionTestsQuery({ submissionSlug });
  const { data: questions, isFetching: isLoadingQuestions } =
    useGetSubmissionQuestionsQuery({ submissionSlug });

  const handleClose = () => {
    closeDrawer();
  };

  useEffect(() => {
    if (!isOpen) {
      setTab('general');
    }
  }, [isOpen]);

  useEffect(() => {
    if (error) {
      toast.newToast('negative', error?.data?.detail);
    }
  }, [error]);

  const isLoading =
    isLoadingSubmission ||
    isLoadingCurriculum ||
    isLoadingTests ||
    isLoadingQuestions ||
    isLoadingCompanySettings ||
    !submission;

  const options = [
    {
      label: t('candidate-drawer-options-menu.generate-ai-review'),
      action: handleAIReview,
      icon: <Brain size='24px' color='#084D6E' />,
    },
    hasAccessToViewApplication && {
      label: t('candidate-drawer-options-menu.view-application'),
      action: navigateToSubmissionApplication,
      icon: <Copy size='24px' color='#084D6E' />,
    },
  ].filter((option) => option.action);

  const filteredCandidates = useMemo(() => {
    const currentCandidate = candidates.find(
      (candidate) => candidate.slug === submissionSlug,
    );
    if (!currentCandidate) return [];
    return candidates.filter(
      (candidate) => candidate.phase === currentCandidate.phase,
    );
  }, [candidates, submissionSlug]);

  const currentIndex = useMemo(() => {
    return filteredCandidates.findIndex(
      (candidate) => candidate.slug === submissionSlug,
    );
  }, [filteredCandidates, submissionSlug]);

  useEffect(() => {}, [filteredCandidates, currentIndex]);

  const handleNext = useCallback(() => {
    if (currentIndex < filteredCandidates.length - 1) {
      const nextCandidate = filteredCandidates[currentIndex + 1];
      if (nextCandidate?.slug) {
        setCurrentSubmissionSlug(nextCandidate.slug);
        const targetUrl = `/${companySlug}/offers/${offer.slug}/submissions/${nextCandidate.slug}`;
        navigate(targetUrl, { replace: true });
      }
    }
  }, [
    currentIndex,
    filteredCandidates,
    navigate,
    offer,
    t,
    toast,
    setCurrentSubmissionSlug,
    companySlug,
  ]);

  const handlePrevious = useCallback(() => {
    if (currentIndex > 0) {
      const prevCandidate = filteredCandidates[currentIndex - 1];
      if (prevCandidate?.slug) {
        setCurrentSubmissionSlug(prevCandidate.slug);
        const targetUrl = `/${companySlug}/offers/${offer.slug}/submissions/${prevCandidate.slug}`;
        navigate(targetUrl, { replace: true });
      }
    }
  }, [
    currentIndex,
    filteredCandidates,
    navigate,
    offer,
    toast,
    t,
    setCurrentSubmissionSlug,
    companySlug,
  ]);

  const isFirstCandidate = currentIndex === 0;
  const isLastCandidate = currentIndex === filteredCandidates.length - 1;

  return (
    <div className='w-full min-h-full flex flex-col p-5 flex-1'>
      <div className='mb-6 flex items-center justify-between'>
        <Button size='icon' variant='primary' onClick={handleClose}>
          <Left size='16px' color='#FFFFFF' />
        </Button>
        <div className='flex items-center uppercase'>
          <Button
            variant='ghost'
            onClick={handlePrevious}
            disabled={isFirstCandidate}
            className='text-dark-blue text-xs font-semibold underline'
          >
            <Left size='20px' color='#084D6E' />
            {t('candidate-drawer.previous-candidate')}
          </Button>
          <Button
            variant='ghost'
            onClick={handleNext}
            disabled={isLastCandidate}
            className='text-dark-blue text-xs font-semibold underline'
          >
            {t('candidate-drawer.next-candidate')}
            <Right size='20px' color='#084D6E' />
          </Button>
        </div>
      </div>

      <div className='flex items-center justify-between mx-5 mb-8'>
        <CandidateHeader
          offer={offer}
          submission={submission}
          setSubmission={setSubmission}
          companySettings={companySettings}
          isLoading={isLoadingSubmission}
        />
      </div>

      <div className='flex flex-col mx-5 mb-4'>
        <div className='flex justify-between mb-1.5'>
          <ul className='flex items-center gap-4 mb-0.5 z-10'>
            <li
              className={cn(
                'font-semibold select-none text-sonic-silver cursor-pointer',
                {
                  'text-vibrant-orange decoration-2 underline underline-offset-[20px] cursor-auto':
                    tab === 'general',
                },
              )}
              onClick={() => setTab('general')}
            >
              {t('general-view')}
            </li>
            <li
              className={cn(
                'font-semibold select-none text-sonic-silver cursor-pointer',
                {
                  'text-vibrant-orange decoration-2 underline underline-offset-[20px] cursor-auto':
                    tab === 'cv',
                },
              )}
              onClick={() => setTab('cv')}
            >
              {t('cv')}
            </li>
            <li
              className={cn(
                'flex justify-center items-center gap-1 cursor-pointer',
                { 'cursor-auto': tab === 'evaluation' },
              )}
              onClick={() => setTab('evaluation')}
            >
              <p
                className={cn('font-semibold select-none text-sonic-silver', {
                  'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                    tab === 'evaluation',
                })}
              >
                {t('evaluation')}
              </p>
              {tests && (
                <div
                  className={cn(
                    'flex items-center justify-center rounded-full w-5 h-5 text-xs font-semibold text-white bg-sonic-silver',
                    {
                      'cursor-default bg-vibrant-orange': tab === 'evaluation',
                    },
                  )}
                >
                  {tests.length}
                </div>
              )}
            </li>
            <li
              className={cn(
                'flex justify-center items-center gap-1 cursor-pointer',
                { 'cursor-auto': tab === 'questions' },
              )}
              onClick={() => setTab('questions')}
            >
              <p
                className={cn('font-semibold select-none text-sonic-silver', {
                  'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                    tab === 'questions',
                })}
              >
                {t('questions')}
              </p>
              {questions && (
                <div
                  className={cn(
                    'flex items-center justify-center rounded-full w-5 h-5 text-xs font-semibold text-white bg-sonic-silver',
                    { 'cursor-default bg-vibrant-orange': tab === 'questions' },
                  )}
                >
                  {questions.questions.length}
                </div>
              )}
            </li>
            <li
              className={cn(
                'flex justify-center items-center gap-1 cursor-pointer',
                { 'cursor-auto': tab === 'notes' },
              )}
              onClick={() => setTab('notes')}
            >
              <p
                className={cn('font-semibold select-none text-sonic-silver', {
                  'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                    tab === 'notes',
                })}
              >
                {t('tabNotes.notes')}
              </p>
              {submission?.notes && (
                <div
                  className={cn(
                    'flex items-center justify-center rounded-full w-5 h-5 text-xs font-semibold text-white bg-sonic-silver',
                    { 'cursor-default bg-vibrant-orange': tab === 'notes' },
                  )}
                >
                  {notesSeparatorLength}
                </div>
              )}
            </li>
          </ul>
          <div className='flex items-center gap-4'>
            <a href={cvUrl} rel='noreferrer' target='_blank'>
              <Button variant='primary' disabled={!cvUrl}>
                <Download size='16px' color='#FFFFFF' />
                {t('download-cv')}
              </Button>
            </a>
            <OptionsMenu options={options} />
          </div>
        </div>
        <Divider />
      </div>

      <div className='relative h-full w-full flex-1'>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {tab === 'general' && (
              <CandidateGeneralView submission={submission} />
            )}
            {tab === 'cv' && <CandidateCurriculum cvUrl={cvUrl} />}
            {tab === 'evaluation' && <CandidateEvaluation tests={tests} />}
            {tab === 'questions' && (
              <CandidateQuestions questions={questions?.questions} />
            )}
            {tab === 'notes' && (
              <CandidateNotes
                submission={submission}
                setSubmission={setSubmission}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CandidateDrawer;
