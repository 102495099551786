import { useToast } from 'common/hooks/useToast';
import {
  useAddSubmissionMutation,
  useGetOfferQuery,
} from 'common/slices/applicationsApi.slice';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import ApplicationTab from './components/ApplicationTab';
import { ApplicationsNewSubmissionSkeleton } from './components/ApplicationsNewSubmissionSkeleton';
import DetailsTab from './components/DetailsTab';
import TabsHeader from './components/TabsHeader';

const SubmissionNew = () => {
  const { t } = useTranslation('applications\\index');
  const toast = useToast();
  const { offerSlug } = useParams();
  const navigate = useNavigate();
  const [createSubmission] = useAddSubmissionMutation();
  const {
    data: offer,
    isLoading,
    error: offerError,
  } = useGetOfferQuery({ offerSlug });
  const [educationEntries, setEducationEntries] = useState([]);
  const [currentEducation, setCurrentEducation] = useState(null);
  const [experienceEntries, setExperienceEntries] = useState([]);
  const [currentExperience, setCurrentExperience] = useState(null);
  const [activeTab, setActiveTab] = useState('application');

  useEffect(() => {
    if (!isLoading && (!offer || offerError)) {
      navigate('/error', { state: { message: t('form.error.offerNotFound') } });
    }
  }, [offer, offerError, isLoading, navigate, t]);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    location: '',
    headline: '',
    summary: '',
    experienceYears: '',
    experience: '',
    questions: offer?.questions?.reduce((acc, question) => {
      acc[question.slug] = '';
      return acc;
    }, {}),
    cvFile: null,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t('form.responses.firstNameNotSpecified')),
    lastName: Yup.string().required(t('form.responses.lastNameNotSpecified')),
    email: Yup.string()
      .email(t('form.responses.invalidEmail'))
      .required(t('form.responses.emailNotSpecified')),
    phone: Yup.string().required(t('form.responses.phoneNotSpecified')),
    location: Yup.string().required(t('form.responses.addressNotSpecified')),
    questions: Yup.object().shape(
      offer?.questions?.reduce((acc, question) => {
        acc[question.slug] =
          question.data_type === 'boolean'
            ? Yup.string()
                .required(t('form.responses.requiredQuestion'))
                .oneOf(['true', 'false'], t('form.responses.requiredQuestion'))
            : Yup.string().required(t('form.responses.requiredQuestion'));
        return acc;
      }, {}),
    ),
    cvFile: Yup.mixed().required(t('form.responses.cvNotUploaded')),
  });

  const handleSubmit = async (values) => {
    if (values.cvFile == null) {
      toast.newToast('negative', t('form.responses.cvNotUploaded'));
      return;
    }
    const submissionData = {
      first_name: values.firstName.trim(),
      last_name: values.lastName.trim(),
      email: values.email.trim(),
      phone: values.phone.trim(),
      location: values.location.trim(),
      headline: values.headline?.trim() || null,
      summary: values.summary?.trim() || null,
      experience_years: values.experienceYears || null,
      experience: values.experience || '',
      experience_entries: experienceEntries,
      education_entries: educationEntries,
      questions: values.questions
        ? offer.questions.map((question) => ({
            question: question.name,
            question_slug: question.slug,
            answer: values.questions[question.slug] || null,
          }))
        : [],
      tests: [],
      url_linkedin: values.url_linkedin || '',
      url_facebook: values.url_facebook || '',
      url_instagram: values.url_instagram || '',
      url_twitter: values.url_twitter || '',
    };

    const formData = new FormData();
    if (values.cvFile) {
      formData.append('submission_pdf_file', values.cvFile);
    }
    formData.append('data', JSON.stringify(submissionData));

    try {
      const response = await createSubmission({
        offerSlug,
        data: formData,
      }).unwrap();
      toast.newToast('positive', t('form.success.submission'));
      const externalSlug = response.external_slug;
      navigate(`/applications/s/${externalSlug}`, {
        state: { submissionData: response },
      });
    } catch (error) {
      toast.newToast('negative', t('form.error.submissionFailed'));
    }
  };

  return (
    <div className='flex justify-center py-8 items-center min-h-screen bg-gray-100 px-4'>
      {isLoading ? (
        <ApplicationsNewSubmissionSkeleton />
      ) : (
        <div className='bg-white w-full max-w-2xl py-16 rounded-lg shadow-lg p-8 mx-auto'>
          <div className='text-center mb-6'>
            <h1 className='text-2xl font-semibold text-gray-800'>
              {offer?.title || t('form.responses.industryNotSpecified')}
            </h1>
            <p className='text-sm text-gray-500'>
              {offer?.location || t('form.responses.addressNotSpecified')}
            </p>
            <p
              className='text-xs text-vibrant-orange bg-[#F16347]/20 rounded-md mx-auto px-2 py-1 mt-2 inline-block'
              style={{ width: 'fit-content', height: '22px' }}
            >
              {offer?.work_type
                ? t(`form.work_type.${offer.work_type}`)
                : t('form.responses.workTypeNotSpecified')}
            </p>
          </div>
          <TabsHeader activeTab={activeTab} setActiveTab={setActiveTab} />
          {activeTab === 'details' ? (
            <DetailsTab offer={offer} setActiveTab={setActiveTab} />
          ) : (
            <ApplicationTab
              initialValues={initialValues}
              validationSchema={validationSchema}
              handleSubmit={handleSubmit}
              educationEntries={educationEntries}
              setEducationEntries={setEducationEntries}
              currentEducation={currentEducation}
              setCurrentEducation={setCurrentEducation}
              experienceEntries={experienceEntries}
              setExperienceEntries={setExperienceEntries}
              currentExperience={currentExperience}
              setCurrentExperience={setCurrentExperience}
              offer={offer}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SubmissionNew;
