import LabelledDisplay from 'common/components/LabelledDisplay';
import { useGetSubmissionByExternalSlugQuery } from 'common/slices/applicationsApi.slice';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { SubmissionDetailsSkeleton } from './components/SubmissionDetailsSkeleton';

const SubmissionDetails = () => {
  const { t } = useTranslation('applications\\index');
  const { externalSlug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const submissionDataFromState = location.state?.submissionData;

  const {
    data: submissionData,
    isFetching,
    error,
  } = useGetSubmissionByExternalSlugQuery(
    { externalSlug },
    { skip: !!submissionDataFromState },
  );

  const finalSubmissionData = submissionDataFromState || submissionData;

  useEffect(() => {
    if (!isFetching && error) {
      navigate('/error', {
        state: { message: t('form.error.submissionNotFound') },
      });
    }
  }, [error, isFetching, navigate, t]);

  if (isFetching) {
    return <SubmissionDetailsSkeleton />;
  }
  if (!finalSubmissionData) {
    return <div>{t('form.error.submissionNotFound')}</div>;
  }

  return (
    <div className='flex justify-center items-center min-h-screen bg-gray-100 px-4'>
      <div className='bg-white w-full max-w-2xl rounded-lg shadow-lg p-8'>
        <div className='text-center mb-4'>
          <h1 className='text-2xl font-semibold text-gray-800'>
            {t('form.title')}
          </h1>
        </div>
        <Formik
          initialValues={{
            firstName: finalSubmissionData.first_name || '',
            lastName: finalSubmissionData.last_name || '',
            location: finalSubmissionData.location || '',
            email: finalSubmissionData.email || '',
            phone: finalSubmissionData.phone || '',
          }}
          onSubmit={(values) => {
            console.log('Submitted Values:', values);
          }}
        >
          {({ values }) => (
            <Form className='space-y-6'>
              <div className='border-t pt-6'>
                <h2 className='text-lg uppercase font-bold text-gray-600 mb-2'>
                  {t('form.personalInformation')}
                </h2>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                  <LabelledDisplay
                    label={t('form.fieldNames.firstName')}
                    value={values.firstName}
                  />

                  <LabelledDisplay
                    label={t('form.fieldNames.lastName')}
                    value={values.lastName}
                    className='text-center'
                  />

                  <LabelledDisplay
                    label={t('form.fieldNames.location')}
                    value={values.location}
                  />
                </div>
              </div>
              <div className='border-t pt-6'>
                <h2 className='text-lg uppercase font-bold text-gray-600 mb-2'>
                  {t('form.contact')}
                </h2>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                  <LabelledDisplay
                    label={t('form.fieldNames.email')}
                    value={values.email}
                  />
                  <LabelledDisplay
                    className='text-center'
                    label={t('form.fieldNames.phone')}
                    value={values.phone}
                  />
                </div>
              </div>
              <div className='border-t pt-6'>
                <h2 className='text-lg uppercase font-bold text-gray-600 mb-2'>
                  {t('form.experienceEntries')}
                </h2>
                {finalSubmissionData?.experience_entries &&
                finalSubmissionData.experience_entries.length > 0 ? (
                  <div className='mb-4'>
                    {finalSubmissionData.experience_entries.map(
                      (entry, index) => (
                        <div key={index} className='mb-6'>
                          <div className='flex justify-between items-center'>
                            <h3 className='text-md font-bold text-gray-800'>
                              {entry.title}{' '}
                              <span className='text-sm text-gray-500'>
                                at {entry.company}
                              </span>
                            </h3>
                            <p className='text-sm text-gray-500'>
                              ({entry.start_date} to{' '}
                              {entry.end_date || t('form.responses.noEndDate')})
                            </p>
                          </div>
                          <p className='text-gray-700 mt-2'>{entry.summary}</p>
                        </div>
                      ),
                    )}
                  </div>
                ) : (
                  <p>{t('form.responses.noExperience')}</p>
                )}
              </div>

              <div className='border-t pt-6'>
                <h2 className='text-lg uppercase font-bold text-gray-600 mb-4'>
                  {t('form.educationEntries')}
                </h2>
                {finalSubmissionData?.education_entries &&
                finalSubmissionData.education_entries.length > 0 ? (
                  <div className='mb-4'>
                    {finalSubmissionData.education_entries.map(
                      (entry, index) => (
                        <div key={index} className='mb-6'>
                          <div className='flex justify-between items-center'>
                            <h3 className='text-md font-bold text-gray-800'>
                              {entry.degree}{' '}
                              <span className='text-sm text-gray-500'>
                                at {entry.school}
                              </span>
                            </h3>
                            <p className='text-sm text-gray-500'>
                              ({entry.start_date} -{' '}
                              {entry.end_date || t('form.responses.noEndDate')})
                            </p>
                          </div>
                          {entry.field_of_study && (
                            <p className='text-gray-700 mt-2'>
                              <strong>
                                {t('form.fieldNames.fieldOfStudy')}:{' '}
                              </strong>
                              {entry.field_of_study}
                            </p>
                          )}
                        </div>
                      ),
                    )}
                  </div>
                ) : (
                  <p>{t('form.responses.noEducation')}</p>
                )}
              </div>

              <div className='border-t pt-6'>
                <h2 className='text-lg uppercase font-bold text-gray-600 mb-2'>
                  {t('form.questions')}
                </h2>
                {finalSubmissionData?.questions &&
                finalSubmissionData.questions.length > 0 ? (
                  <div className='space-y-4'>
                    {finalSubmissionData.questions.map((question, index) => (
                      <div key={index} className='mb-2'>
                        <h3 className='text-md font-semibold text-gray-600'>
                          {question.question}:
                        </h3>
                        <p className='text-base text-gray-800'>
                          {(() => {
                            if (
                              question.answer === true ||
                              question.answer === 'true'
                            ) {
                              return t('form.yes');
                            }
                            if (
                              question.answer === false ||
                              question.answer === 'false'
                            ) {
                              return t('form.no');
                            }
                            return (
                              question.answer || t('form.responses.notAnswered')
                            );
                          })()}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>{t('form.responses.noQuestions')}</p>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SubmissionDetails;
