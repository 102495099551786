import { cva } from 'class-variance-authority';
import { cn } from 'common/utils';
import React, { useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

const SourceTooltip = ({ content, alignment, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const triggerRef = useRef(null);

  const tooltipVariants = cva(
    ['relative flex flex-1', { 'cursor-pointer': content }],
    {
      variants: {
        alignment: {
          start: 'justify-start',
          center: 'justify-center',
          end: 'justify-end',
        },
      },
      defaultVariants: {
        alignment: 'center',
      },
    },
  );

  useLayoutEffect(() => {
    if (showTooltip && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setPosition({
        top: rect.top + window.scrollY,
        left: rect.left + rect.width / 2 + window.scrollX,
      });
    }
  }, [showTooltip]);

  return (
    <div className={cn(tooltipVariants({ alignment }))}>
      <div
        ref={triggerRef}
        onClick={() => setShowTooltip(!showTooltip)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {children}
      </div>
      {showTooltip &&
        ReactDOM.createPortal(
          <div
            className='absolute z-50 transition-opacity transition-transform duration-300 ease-out'
            style={{
              top: position.top,
              left: position.left,
              transform: 'translate(-50%, calc(-100% - 8px))',
            }}
          >
            <div className='relative'>
              <div className='bg-raisin-black text-white rounded p-2 text-xs font-semibold transition-opacity duration-300 ease-out'>
                {content}
              </div>
              <div
                className='absolute bottom-[-6px] left-1/2'
                style={{
                  transform: 'translateX(-50%)',
                  width: 0,
                  height: 0,
                  borderLeft: '6px solid transparent',
                  borderRight: '6px solid transparent',
                  borderTop: '6px solid #1c1c1e',
                }}
              />
            </div>
          </div>,
          document.body,
        )}
    </div>
  );
};

export default SourceTooltip;
