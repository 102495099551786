import Check from 'assets/icons/Check';
import Close from 'assets/icons/Close';
import Danger from 'assets/icons/Danger';
import Error from 'assets/icons/Error';
import Info from 'assets/icons/Info';
import { useToast } from 'common/hooks/useToast';
import { cn } from 'common/utils';
import React, { useEffect, useRef } from 'react';

const toastTypes = {
  neutral: {
    icon: null,
    styles: {
      border: 'border-l-4 border-gray-400',
      background: 'bg-white',
      text: 'text-gray-900',
    },
  },
  informative: {
    icon: <Info size='18px' color='#007bff' />,
    styles: {
      border: 'border-l-4 border-blue-500',
      background: 'bg-white',
      text: 'text-gray-900',
    },
  },
  positive: {
    icon: <Check size='22px' color='#4CAF50' />,
    styles: {
      border: 'border-l-4 border-green-500',
      background: 'bg-white',
      text: 'text-gray-900',
    },
  },
  warning: {
    icon: <Danger size='18px' color='#FFC107' />,
    styles: {
      border: 'border-l-4 border-yellow-500',
      background: 'bg-white',
      text: 'text-gray-900',
    },
  },
  negative: {
    icon: <Error size='18px' color='#DC3545' />,
    styles: {
      border: 'border-l-4 border-red-500',
      background: 'bg-white',
      text: 'text-gray-900',
    },
  },
};

const Toast = ({ id, type, message, timeout = 5000 }) => {
  const toast = useToast();
  const timerRef = useRef(null);
  const wrapperRef = useRef(null);

  const handleDismiss = () => {
    toast.closeToast(id, wrapperRef);
  };

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      handleDismiss();
    }, timeout);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const {
    icon,
    styles: { border, background, text },
  } = toastTypes[type];

  return (
    <div
      key={id}
      ref={wrapperRef}
      className={cn(
        'flex justify-between items-center px-4 py-3 rounded-md shadow-lg w-[380px]',
        'animate-toast-in z-50',
        border,
        background,
      )}
    >
      <div className='flex items-center gap-3 w-full'>
        {icon}
        <p className={cn('text-sm font-medium flex-1', text)}>{message}</p>
      </div>
      <Close
        size='20px'
        color='#000000'
        onClick={handleDismiss}
        className='cursor-pointer opacity-70 hover:opacity-100 transition-opacity'
      />
    </div>
  );
};

export default Toast;
