import useTranslation from 'common/hooks/useTranslation';
import React from 'react';

const formatDate = (date) =>
  new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    year: 'numeric',
  });

const EducationSection = ({ educationEntries, education }) => {
  const isValidEntries =
    Array.isArray(educationEntries) && educationEntries.length > 0;
  const { t } = useTranslation('offers\\details');

  return (
    <section className='w-full flex flex-col gap-2 text-dark-charcoal'>
      <h4 className='text-dark-liver font-semibold uppercase mb-2'>
        {t('education')}
      </h4>
      {isValidEntries ? (
        educationEntries.map(
          ({ degree, school, start_date, end_date }, index) => (
            <div
              key={index}
              className='border-b border-gray-200 pb-2 last:border-none'
            >
              <div className='flex justify-between text-sm text-sonic-silver font-semibold'>
                <span>{degree || 'N/A'}</span>
                <span className='text-cool-grey font-normal'>
                  ({formatDate(start_date || 'N/A')} to{' '}
                  {end_date ? formatDate(end_date || 'N/A') : 'Present'})
                </span>
              </div>
              <p className='text-sm mt-2'>{school || 'N/A'}</p>
            </div>
          ),
        )
      ) : (
        <p className='text-sm'>{education || 'N/A'}</p>
      )}
    </section>
  );
};

export default EducationSection;
