import RadioChecked from 'assets/icons/RadioChecked';
import RadioUnchecked from 'assets/icons/RadioUnchecked';
import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import LabelledInput from 'common/components/LabelledInput';
import LabelledPhoneInput from 'common/components/LabelledPhoneInput';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import CVModal from 'offers/details/components/modals/CVModal';
import React from 'react';
import { useTranslation } from 'react-i18next';

import EntrySection from './EntrySection';

const ApplicationTab = ({
  initialValues,
  validationSchema,
  handleSubmit,
  educationEntries,
  setEducationEntries,
  currentEducation,
  setCurrentEducation,
  experienceEntries,
  setExperienceEntries,
  currentExperience,
  setCurrentExperience,
  offer,
}) => {
  const { t } = useTranslation('applications\\index');

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form
          className='space-y-6'
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          {/* Personal Information Section */}
          <div className='border-t-2 pt-6'>
            <h2 className='text-lg uppercase font-semibold text-gray-600 mb-4'>
              {t('form.personalInformation')}
            </h2>
            <div className='grid grid-cols-1 md:grid-cols-2 md:gap-1'>
              <LabelledInput
                name='firstName'
                placeholder={t('form.fieldNames.firstName') + ' *'}
                type='text'
              />
              <LabelledInput
                name='lastName'
                placeholder={t('form.fieldNames.lastName') + ' *'}
                type='text'
              />
            </div>
            <LabelledInput
              name='location'
              placeholder={t('form.fieldNames.location') + ' *'}
              type='text'
            />
          </div>

          {/* Contact Section */}
          <div className='border-t-2 pt-6'>
            <h2 className='text-lg uppercase font-semibold text-gray-600 mb-4'>
              {t('form.contact')}
            </h2>
            <LabelledInput
              name='email'
              placeholder={t('form.fieldNames.email') + ' *'}
              type='email'
            />
            <LabelledPhoneInput
              name='phone'
              placeholder={t('form.fieldNames.phone') + ' *'}
              type='tel'
            />
          </div>

          {/* Curriculum Section */}
          <div className='border-t-2 pt-6 mb-8'>
            <h2 className='text-lg uppercase font-semibold text-gray-600 mb-4'>
              Curriculum
            </h2>
            <Field name='cvFile'>
              {({ field, form }) => (
                <CVModal
                  onFileUpload={(uploadedFile) => {
                    form.setFieldValue('cvFile', uploadedFile);
                    form.setFieldTouched('cvFile', true, false);
                  }}
                  hasError={form.touched.cvFile && form.errors.cvFile}
                  value={field.value}
                />
              )}
            </Field>
            <ErrorMessage
              name='cvFile'
              component='p'
              className='error text-bittersweet-shimmer text-xs font-semibold mt-1'
            />
          </div>

          {/* Education Section */}
          <EntrySection
            title={t('form.education')}
            entries={educationEntries}
            currentEntry={currentEducation}
            setCurrentEntry={setCurrentEducation}
            saveEntry={() => {
              setEducationEntries((prev) => [...prev, currentEducation]);
              setCurrentEducation(null);
            }}
            removeEntry={(index) =>
              setEducationEntries((prev) => prev.filter((_, i) => i !== index))
            }
            fields={[
              {
                name: 'degree',
                placeholder: 'form.fieldNames.degree',
                type: 'text',
              },
              {
                name: 'school',
                placeholder: 'form.fieldNames.school',
                type: 'text',
              },
              {
                name: 'field_of_study',
                placeholder: 'form.fieldNames.fieldOfStudy',
                type: 'text',
              },
              {
                name: 'start_date',
                placeholder: 'form.fieldNames.start_date',
                type: 'date',
              },
              {
                name: 'end_date',
                placeholder: 'form.fieldNames.end_date',
                type: 'date',
              },
            ]}
            addButtonText='form.addButton'
          />

          {/* Experience Section */}
          <EntrySection
            title={t('form.experience')}
            entries={experienceEntries}
            currentEntry={currentExperience}
            setCurrentEntry={setCurrentExperience}
            saveEntry={() => {
              setExperienceEntries((prev) => [...prev, currentExperience]);
              setCurrentExperience(null);
            }}
            removeEntry={(index) =>
              setExperienceEntries((prev) => prev.filter((_, i) => i !== index))
            }
            fields={[
              {
                name: 'title',
                placeholder: 'form.fieldNames.title',
                type: 'text',
              },
              {
                name: 'summary',
                placeholder: 'form.fieldNames.summary',
                type: 'text',
              },
              {
                name: 'company',
                placeholder: 'form.fieldNames.company',
                type: 'text',
              },
              {
                name: 'industry',
                placeholder: 'form.fieldNames.industry',
                type: 'text',
              },
              {
                name: 'start_date',
                placeholder: 'form.fieldNames.start_date',
                type: 'date',
              },
              {
                name: 'end_date',
                placeholder: 'form.fieldNames.end_date',
                type: 'date',
              },
            ]}
            addButtonText='form.addButton'
          />

          {/* Questions Section */}
          {offer?.questions?.length > 0 && (
            <div className='border-t pt-6'>
              <h2 className='text-lg uppercase font-semibold text-gray-600 mb-4'>
                {t('form.questions')}
              </h2>
              <div className='space-y-6'>
                {offer.questions.map((question) => (
                  <div key={question.slug} className='flex flex-col gap-2'>
                    <label
                      htmlFor={question.slug}
                      className='text-sm font-semibold text-gray-600'
                    >
                      {question.name + ' *'}
                    </label>
                    {question.description && (
                      <p className='text-sm text-gray-500'>
                        {question.description}
                      </p>
                    )}
                    {question.data_type === 'boolean' ? (
                      <div className='flex flex-col gap-2 mt-2'>
                        <label className='flex items-center gap-2 cursor-pointer'>
                          <Field
                            type='radio'
                            id={`${question.slug}-yes`}
                            name={`questions.${question.slug}`}
                            value='true'
                            className='h-5'
                          />
                          {({ field }) =>
                            field.checked ? (
                              <RadioChecked size='24' color='#084D6E' />
                            ) : (
                              <RadioUnchecked size='24' color='#084D6E' />
                            )
                          }
                          <span className='text-sm text-dark-blue'>
                            {t('form.yes')}
                          </span>
                        </label>
                        <label className='flex items-center gap-2 cursor-pointer'>
                          <Field
                            type='radio'
                            id={`${question.slug}-no`}
                            name={`questions.${question.slug}`}
                            value='false'
                            className='h-5'
                          />
                          {({ field }) =>
                            field.checked ? (
                              <RadioChecked size='24' color='#084D6E' />
                            ) : (
                              <RadioUnchecked size='24' color='#084D6E' />
                            )
                          }
                          <span className='text-sm text-dark-blue'>
                            {t('form.no')}
                          </span>
                        </label>
                        <ErrorMessage
                          name={`questions.${question.slug}`}
                          component='p'
                          className='error text-bittersweet-shimmer text-xs font-semibold'
                        />
                      </div>
                    ) : (
                      <LabelledInput
                        name={`questions.${question.slug}`}
                        placeholder={question.name}
                        type='text'
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className='sticky bottom-0 py-4 z-10 flex justify-end w-full'>
            <Button
              type='submit'
              variant='primary'
              className='px-6 py-2 opacity-100'
              disabled={isSubmitting}
            >
              {isSubmitting ? t('form.loading') : t('form.submitButton')}
              <Right size='16px' color='#FFFFFF' />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ApplicationTab;
