import { Dialog, Transition } from '@headlessui/react';
import Right from 'assets/icons/Right';
import SAP from 'assets/icons/SAP';
import Button from 'common/components/Button';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import {
  useGetExternalSourceOffersMutation,
  useSetupExternalSourceMutation,
} from 'common/slices/offerApi.slice';
import { cn } from 'common/utils';
import React, { Fragment, useEffect, useState } from 'react';
import { FaMagnifyingGlass } from 'react-icons/fa6';

const DataSourceModal = ({ isOpen, setIsOpen, offerSlug, externalOfferId }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOffer, setSelectedOffer] = useState(null);
  const { t } = useTranslation('offers\\details');
  const toast = useToast();

  const [getExternalSourceOffers, { data, isLoading, error }] =
    useGetExternalSourceOffersMutation();

  const [setupExternalSource, { isLoading: isSaving }] =
    useSetupExternalSourceMutation();

  useEffect(() => {
    if (isOpen && offerSlug) {
      getExternalSourceOffers({ offerSlug });
    }
  }, [isOpen, offerSlug, getExternalSourceOffers]);

  useEffect(() => {
    if (data && externalOfferId) {
      const foundOffer = data.find(
        (offer) =>
          offer.external_offer_id.toString() === externalOfferId.toString(),
      );
      if (foundOffer) {
        setSelectedOffer(foundOffer);
      }
    }
  }, [data, externalOfferId]);

  const externalOffers = data || [];
  const filteredOffers = externalOffers.filter(
    (offer) =>
      offer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      offer.external_offer_id
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
  );

  const closeModal = () => {
    if (!isSaving) {
      setIsOpen(false);
    }
  };
  const connectToDataSource = async () => {
    if (!selectedOffer) return;

    try {
      const payload = {
        offerSlug,
        externalSourceName: 'SAPSF',
        data: { external_offer_id: selectedOffer.external_offer_id },
      };

      await setupExternalSource(payload).unwrap();
      toast.newToast('positive', t('modals.setup-data-source.toasts.success'));
      closeModal();
    } catch (err) {
      switch (err?.status) {
        case 400:
          console.error(err.data);
          toast.newToast('negative', err.data?.errors[0].detail);
          break;
        case 403:
          toast.newToast(
            'negative',
            t('modals.setup-data-source.toasts.forbidden'),
          );
          break;
        default:
          toast.newToast(
            'negative',
            t('modals.setup-data-source.toasts.something-wrong'),
          );
      }
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>
        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel
                className={cn(
                  'flex flex-col w-full max-w-md transform overflow-hidden rounded bg-white p-10 text-left gap-6 shadow-xl transition-all relative',
                )}
              >
                <Dialog.Title className='flex flex-col w-full items-center gap-8'>
                  <SAP size='64px' color='#084D6E' />
                  <h1 className='text-2xl font-semibold text-dark-charcoal'>
                    {t('modals.setup-data-source.title')}
                  </h1>
                </Dialog.Title>
                <Dialog.Description className='flex flex-col w-full items-center text-sm text-gray-600'>
                  {t('modals.setup-data-source.description')}
                </Dialog.Description>
                <div className='relative'>
                  <input
                    className='w-full px-4 py-2 border rounded-md text-input outline-none'
                    type='text'
                    placeholder='PESQUISA'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <span className='absolute right-4 top-3 text-gray-400'>
                    <FaMagnifyingGlass />
                  </span>
                </div>
                <div className='max-h-60 overflow-y-auto border rounded-md'>
                  {isLoading ? (
                    <p className='text-center text-gray-500 p-4'>Loading...</p>
                  ) : error ? (
                    <p className='text-center text-red-500 p-4'>
                      Error loading offers
                    </p>
                  ) : filteredOffers.length > 0 ? (
                    filteredOffers.map((offer) => (
                      <div
                        key={offer.external_offer_id}
                        className={cn(
                          'p-3 cursor-pointer border-b hover:bg-gray-100',
                          selectedOffer?.external_offer_id ===
                            offer.external_offer_id && 'bg-red-100',
                        )}
                        onClick={() => setSelectedOffer(offer)}
                      >
                        <p className='text-sm font-medium text-gray-800'>
                          {offer.name}
                        </p>
                        <p className='text-xs text-gray-500'>
                          ID: {offer.external_offer_id}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p className='text-center text-gray-500 p-4'>
                      No results found
                    </p>
                  )}
                </div>
                <div className='w-full flex items-center justify-center gap-2'>
                  <Button variant='secondary' onClick={closeModal}>
                    {t('modals.setup-data-source.buttons.cancel')}
                  </Button>
                  <Button
                    variant='primary'
                    disabled={!selectedOffer}
                    onClick={connectToDataSource}
                  >
                    {t('modals.setup-data-source.buttons.save')}
                    <Right size='16px' color='#FFFFFF' />
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DataSourceModal;
