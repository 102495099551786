import React from 'react';
import { useTranslation } from 'react-i18next';

const TabsHeader = ({ activeTab, setActiveTab }) => {
  const { t } = useTranslation('applications\\index');

  return (
    <div className='-mx-8 mb-6'>
      <div className='flex justify-center w-full border-b pb-2'>
        <button
          type='button'
          onClick={() => setActiveTab('details')}
          className={`px-4 py-2 ${
            activeTab === 'details'
              ? 'border-b-2 border-vibrant-orange text-vibrant-orange'
              : 'text-gray-500'
          }`}
        >
          {t('information')}
        </button>
        <button
          type='button'
          onClick={() => setActiveTab('application')}
          className={`px-4 py-2 ${
            activeTab === 'application'
              ? 'border-b-2 border-vibrant-orange text-vibrant-orange'
              : 'text-gray-500'
          }`}
        >
          {t('application')}
        </button>
      </div>
    </div>
  );
};

export default TabsHeader;
