import Add from 'assets/icons/Add';
import Check from 'assets/icons/Check';
import Trash from 'assets/icons/Trash';
import Button from 'common/components/Button';
import LabelledInput from 'common/components/LabelledInput';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EntrySection = ({
  title,
  entries,
  currentEntry,
  setCurrentEntry,
  saveEntry,
  removeEntry,
  fields,
  addButtonText,
}) => {
  const { t } = useTranslation('applications\\index');

  return (
    <div className='border-t-2 pt-6 relative'>
      <div className='flex justify-between items-center'>
        <h2 className='text-lg uppercase text-gray-600 font-semibold mb-4'>
          {title}
        </h2>
        <Button
          type='button'
          variant='secondary'
          onClick={() =>
            setCurrentEntry(
              fields.reduce(
                (acc, { name, type }) => ({
                  ...acc,
                  [name]: type === 'checkbox' ? false : '',
                }),
                {},
              ),
            )
          }
        >
          {t(addButtonText)}
          <Add size='14px' />
        </Button>
      </div>
      {entries.map((entry, index) => (
        <div
          key={index}
          className='flex flex-col mb-4 border-b transition-all mt-2 bg-gray-100 hover:shadow-md rounded-lg'
        >
          <div className='grid grid-cols-1 gap-y-1 p-4 flex-grow'>
            {Object.entries(entry).map(([key, value]) => (
              <div key={key} className='flex flex-col'>
                <span className='text-md font-semibold text-gray-600'>
                  {t(`form.fieldNames.${key}`, key.replace(/_/g, ' '))}
                </span>
                <span className='text-sm text-gray-800 py-1'>
                  {value || '-'}
                </span>
              </div>
            ))}
            <div className='flex justify-end mt-auto'>
              <Trash
                size={20}
                className='cursor-pointer text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full'
                onClick={() => removeEntry(index)}
              />
            </div>
          </div>
        </div>
      ))}
      {currentEntry && (
        <>
          {fields.map(({ name, placeholder, type }) => (
            <LabelledInput
              key={name}
              name={name}
              placeholder={t(placeholder)}
              type={type}
              value={currentEntry[name]}
              onChange={(e) =>
                setCurrentEntry((prev) => ({ ...prev, [name]: e.target.value }))
              }
            />
          ))}
          <div className='flex justify-end'>
            <Button
              type='button'
              variant='primary'
              className='mt-4 px-4 min-w-16 flex items-center gap-2'
              onClick={saveEntry}
            >
              {t('form.saveButton')} <Check size='16px' color='#ffffff' />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default EntrySection;
