import { Menu, Transition } from '@headlessui/react';
import More from 'assets/icons/More';
import useTranslation from 'common/hooks/useTranslation';
import { cn } from 'common/utils';
import React, { Fragment } from 'react';

const OptionsMenu = ({ options }) => {
  const { t } = useTranslation('offers\\details');

  if (!(options.length > 0)) {
    return null;
  }

  return (
    <Menu as='div' className='relative inline-block z-10'>
      <Menu.Button className='border rounded-md p-2.5'>
        <More size='16px' color='#7A7A7A' />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items
          className={cn(
            'absolute right-0 mt-2 w-56 origin-top-right',
            'divide-y divide-ghost-white bg-white rounded-md shadow-lg',
            'ring-1 ring-dark-charcoal ring-opacity-5 focus:outline-none',
          )}
        >
          {options.map((option, index) => (
            <Menu.Item key={index}>
              {({ active }) => (
                <button
                  className={cn(
                    'flex w-full items-center px-4 py-4 text-sm gap-2.5',
                    'rounded-b-md',
                    {
                      'bg-vibrant-orange bg-opacity-10': active,
                    },
                  )}
                  onClick={option.action}
                >
                  {option.icon && <span>{option.icon}</span>}
                  <p className='text-sm text-dark-charcoal truncate'>
                    {t(option.label)}
                  </p>
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default OptionsMenu;
