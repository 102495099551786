import { apiSlice } from 'common/slices/api.slice';
import { queryBuilder } from 'common/utils';

export const companyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: ({
        page = 1,
        size = 50,
        name,
        slug,
        location,
        orderBy,
        with_managers = true,
      }) => ({
        url: queryBuilder('/companies/', {
          page,
          size,
          name,
          slug,
          location,
          orderBy,
          with_managers,
        }),
      }),
      providesTags: ['Company'],
    }),
    updateCompany: builder.mutation({
      query: ({ companyId, data }) => ({
        url: `/companies/${companyId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Company'],
    }),
    getCurrentCompanySettings: builder.query({
      query: ({ companyId }) => ({
        url: `/companies/${companyId}/settings`,
      }),
      providesTags: ['CompanySetting'],
    }),
    updateCurrentCompanySettings: builder.mutation({
      query: ({ companyId, data }) => ({
        url: `/companies/${companyId}/settings`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['CompanySetting'],
    }),
    updateCurrentCompanyIntegration: builder.mutation({
      query: ({ companySlug, integrationSlug, data }) => ({
        url: `/companies/${companySlug}/integrations?integration_name=${integrationSlug}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['CompanySetting'],
    }),
    getCompanyDetail: builder.query({
      query: ({ slug }) => ({
        url: `/companies/${slug}`,
      }),
      providesTags: ['Company'],
    }),
  }),
});

export const {
  useGetCurrentCompanySettingsQuery,
  useUpdateCurrentCompanySettingsMutation,
  useUpdateCurrentCompanyIntegrationMutation,
  useGetCompaniesQuery,
  useUpdateCompanyMutation,
  useGetCompanyDetailQuery,
} = companyApiSlice;
