import { cn } from 'common/utils';
import React from 'react';

const FileAdd = ({ size = 24, color = '#084D6E', onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 25'
      fill='none'
      className={cn({ 'cursor-pointer': onClick })}
      onClick={onClick}
    >
      <rect width='24' height='24' transform='translate(0 0.5)' fill='white' />
      <path
        d='M20 12V7.5L15.5 2.5H5C4.44771 2.5 4 2.94771 4 3.5V21.5C4 22.0523 4.44771 22.5 5 22.5H11'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 15V22'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13 18.5H16.5H20'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 2.5V7.5H20'
        stroke={color}
        strokeWidth='1.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default FileAdd;
