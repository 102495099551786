import { Menu, Transition } from '@headlessui/react';
import Bookmark from 'assets/icons/Bookmark';
import Brain from 'assets/icons/Brain';
import Comment from 'assets/icons/Comment';
import FileEditingEmpty from 'assets/icons/FileEditingEmpty';
import FileEditingOne from 'assets/icons/FileEditingOne';
import Fireworks from 'assets/icons/Fireworks';
import More from 'assets/icons/More';
import PeopleTooltip from 'assets/icons/PeopleTooltip';
import Reject from 'assets/icons/Reject';
import Avatar from 'common/components/Avatar';
import useNavigate from 'common/hooks/useNavigate';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import { useUpdatePhaseMutation } from 'common/slices/submissionApi.slice';
import { cn, formatCandidateName } from 'common/utils';
import { isNull } from 'lodash';
import React, { Fragment } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import SourceTooltip from '../../../../common/components/SourceTooltip';

const kanbanStyles = {
  menuItemButton: () =>
    cn(
      'w-full px-4 py-3 flex items-center gap-2.5',
      'text-sm text-left text-gray-700',
      'rounded-md hover:bg-vibrant-orange hover:bg-opacity-10',
    ),
  moreButton: () =>
    cn(
      'bg-white transition-all ease-in-out z-0',
      'hover:bg-bright-grey hover:bg-opacity-50 rounded outline-none',
    ),
};

const KanbanItem = ({
  index,
  columnId,
  offer,
  compact,
  handleHired,
  submission,
  handleFavoriteChanged,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation('offers\\details');
  const [updatePhase] = useUpdatePhaseMutation();

  const handleRejected = async () => {
    try {
      await updatePhase({
        submissionId: submission.id,
        nextPhase: 'rejected',
      }).unwrap();
      toast.newToast('positive', t('toasts.success-mark-rejected'));
      return true;
    } catch (err) {
      switch (err?.status) {
        case 400:
          toast.newToast('negative', err?.data.errors[0].detail);
          break;
        case 403:
          toast.newToast('negative', err?.data.errors[0].detail);
          break;
        default:
          toast.newToast('negative', t('kanban.toasts.something-wrong'));
      }
      return false;
    }
  };

  const {
    is_hired: isHired,
    is_favorite: isFavorite,
    avg_assessment,
    candidate,
    tests,
    n_notes,
    updated_at,
  } = submission;

  const qualitativeTests = tests.filter(
    (t) => t.label !== 'EVALUATION' && t.label !== 'OTHER' && !isNull(t.value),
  );

  const getDraggableStyle = (isDragging, draggableStyle) => ({
    cursor: isDragging ? 'grabbing' : 'pointer',
    ...draggableStyle,
  });

  const candidateName = candidate && formatCandidateName(candidate);
  const fullCandidateName = candidate && formatCandidateName(candidate, true);
  const isNew = new Date() - new Date(updated_at) < 24 * 60 * 60 * 1000;

  const KanbanItemOptions = () => {
    return (
      <Menu as='div' className='relative inline-flex'>
        <Menu.Button
          className={kanbanStyles.moreButton()}
          onClick={(event) => event && event.stopPropagation()}
        >
          <More />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items
            className={cn(
              'absolute top-full right-0 w-max origin-top-right z-10',
              'divide-y divide-ghost-white bg-white rounded-md shadow-lg',
              'ring-1 ring-dark-charcoal ring-opacity-5 focus:outline-none',
            )}
          >
            <Menu.Item>
              <button
                className={kanbanStyles.menuItemButton()}
                onClick={(event) => {
                  event && event.stopPropagation();
                  navigate(
                    `/offers/${offer.slug}/submissions/${submission.slug}`,
                  );
                }}
              >
                <PeopleTooltip color='#084D6E' />
                {t('kanban.item-options.show-details')}
              </button>
            </Menu.Item>
            <Menu.Item>
              <button
                className={kanbanStyles.menuItemButton()}
                onClick={(event) => {
                  event && event.stopPropagation();
                  handleFavoriteChanged(columnId, submission.slug, !isFavorite);
                }}
              >
                {isFavorite ? (
                  <>
                    <Bookmark color='#084D6E' />
                    {t('kanban.item-options.unmark-favorite')}
                  </>
                ) : (
                  <>
                    <Bookmark />
                    {t('kanban.item-options.mark-favorite')}
                  </>
                )}
              </button>
            </Menu.Item>
            <Menu.Item>
              <button
                className={kanbanStyles.menuItemButton()}
                onClick={(event) => {
                  event && event.stopPropagation();
                  handleRejected();
                }}
              >
                <>
                  <Reject />
                  {t('kanban.item-options.mark-rejected')}
                </>
              </button>
            </Menu.Item>
            {columnId === 'interview' && !isHired ? (
              <Menu.Item>
                <button
                  className={kanbanStyles.menuItemButton()}
                  onClick={(event) => {
                    event && event.stopPropagation();
                    handleHired(submission?.slug);
                  }}
                >
                  <Fireworks color='#084D6E' />
                  {t('kanban.item-options.mark-selected')}
                </button>
              </Menu.Item>
            ) : null}
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const transformSourceName = (source) => {
    if (!source) return '';
    if (source === 'VALIDATE_API') return 'Validate';
    if (source === 'SAPSF') return 'SAP SF';
    if (source === 'WORKABLE') return 'Workable';
    return '';
  };

  const capitalize = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const displaySourceName = capitalize(
    transformSourceName(submission?.source_name),
  );

  const getAvatarBorderColor = () => {
    const sourceName = submission?.source_name || '';
    switch (sourceName) {
      case 'SAPSF':
        return 'border-dark-blue';
      case 'VALIDATE_API':
        return 'border-vibrant-orange';
      case 'WORKABLE':
        return 'border-gray-300';
      default:
        return 'border-gray-300';
    }
  };

  return (
    <Draggable draggableId={candidate?.id} index={index}>
      {(provided, snapshot) => (
        <div
          className={cn(
            'min-w-[263px] w-full p-2 bg-white border border-white',
            'flex justify-between items-center rounded-lg shadow',
            'hover:border-dark-blue hover:border-opacity-25 cursor-pointer',
          )}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getDraggableStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}
          onClick={() =>
            navigate(`/offers/${offer.slug}/submissions/${submission.slug}`)
          }
        >
          {compact ? (
            <div className='flex w-full items-center justify-start gap-2'>
              <div className='relative group overflow-visible'>
                <div className='relative group'>
                  <SourceTooltip
                    content={`${t('tooltip.sent_by')} ${displaySourceName}`}
                  >
                    <div className='relative'>
                      <Avatar
                        className={`border-2 rounded-full ${getAvatarBorderColor()}`}
                        size='small'
                        name={candidateName}
                      />
                      {isFavorite && (
                        <div className='absolute bottom-2 left-4 rounded-full p-0.5'>
                          <Bookmark
                            fillColor='#f16347'
                            size='14'
                            color='#F16347'
                          />
                        </div>
                      )}
                    </div>
                  </SourceTooltip>
                </div>
              </div>
              <h1 className='text-dark-liver font-bold flex-1 text-start flex items-start'>
                {fullCandidateName}
              </h1>
              {isNew && (
                <div className='flex items-center'>
                  <span className='ml-1 bg-vibrant-yellow text-dark-blue px-0.5 py-0.5 text-[10px] rounded-md font-semibold self-start uppercase leading-none'>
                    {t('new')}
                  </span>
                </div>
              )}
              {submission?.ai_info?.classification ? (
                <div className='flex items-center text-sm text-vibrant-orange'>
                  <Brain size='16' color='currentColor' />
                  <div className='pl-1'>
                    {offer?.external_source &&
                    offer.external_source['name'] === 'SAPSF' &&
                    submission.ai_info.classification_percentage
                      ? `${submission.ai_info.classification_percentage.toFixed(
                          0,
                        )}%`
                      : submission.ai_info.classification.toFixed(1)}
                  </div>
                </div>
              ) : null}
              {(columnId === 'classification' || columnId === 'interview') && (
                <div className='flex items-center text-sm text-vibrant-orange'>
                  {avg_assessment ? (
                    <>
                      <FileEditingOne size='16' color='currentColor' />
                      <div className='pl-1'>{`${avg_assessment}%`}</div>
                    </>
                  ) : (
                    <FileEditingEmpty size='16' color='#D3D3D3' />
                  )}
                </div>
              )}
              {columnId === 'interview' && isHired ? (
                <div className='text-sm text-vibrant-orange'>
                  <Fireworks size='16' color='currentColor' />
                </div>
              ) : null}
              <KanbanItemOptions />
            </div>
          ) : (
            <div className='flex w-full flex-col px-2 py-3 gap-4'>
              <div className='flex justify-between gap-2 items-center'>
                <h1 className='text-dark-liver text-xl font-bold flex-1 text-start flex items-start'>
                  {fullCandidateName}
                </h1>
                {isNew && (
                  <div className='flex items-center'>
                    <span className='ml-1 bg-vibrant-yellow text-dark-blue px-0.5 py-0.5 text-[10px] rounded-md font-semibold self-start uppercase leading-none'>
                      {t('new')}
                    </span>
                  </div>
                )}
                <div className='relative inline-flex gap-1'>
                  {isFavorite && <Bookmark size='24' color='#F16347' />}
                  {submission?.ai_info?.classification && (
                    <div className='flex items-center text-sm text-vibrant-orange'>
                      <Brain size='24' color='currentColor' />
                      <div className='pl-1'>
                        {offer?.external_source &&
                        offer.external_source['name'] === 'SAPSF' &&
                        submission.ai_info.classification_percentage
                          ? `${submission.ai_info.classification_percentage.toFixed(
                              0,
                            )}%`
                          : submission.ai_info.classification.toFixed(1)}
                      </div>
                    </div>
                  )}
                  <div className='flex justify-between items-center gap-2'>
                    {(columnId === 'classification' ||
                      columnId === 'interview') && (
                      <div className='flex items-center text-sm text-vibrant-orange'>
                        {avg_assessment ? (
                          <>
                            <FileEditingOne size='24' color='currentColor' />
                            <div className='pl-1'>{`${avg_assessment}%`}</div>
                          </>
                        ) : (
                          <FileEditingEmpty size='24' color='#d3d3d3' />
                        )}
                      </div>
                    )}
                    {columnId === 'interview' && isHired ? (
                      <Fireworks size='24' color='#F16347' />
                    ) : null}
                    <KanbanItemOptions />
                  </div>
                </div>
              </div>
              <div className='flex flex-row justify-between items-center'>
                <div className='flex flex-row gap-2'>
                  <Comment size='24px' name={candidateName} />
                  <div className='text-sm text-cool-grey mr-3'>{n_notes}</div>
                  {qualitativeTests.map((test) => (
                    <SourceTooltip key={test.name} content={`${test.value}`}>
                      <div
                        className={cn(
                          'px-2 py-1 text-xs font-semibold rounded flex justify-center',
                          'bg-gray-200 text-gray-500',
                        )}
                      >
                        {test.value.slice(0, 15)}
                        {test.value.length > 15 ? '...' : ''}
                      </div>
                    </SourceTooltip>
                  ))}
                </div>
                <div className='relative group'>
                  <SourceTooltip
                    content={`${t('tooltip.sent_by')} ${displaySourceName}`}
                  >
                    <Avatar
                      className={`border-2 rounded-full ${getAvatarBorderColor()}`}
                      size='small'
                      name={candidateName}
                    />
                  </SourceTooltip>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default KanbanItem;
