import { cva } from 'class-variance-authority';
import { cn } from 'common/utils';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

const Tooltip = ({ content, alignment, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const triggerRef = useRef(null);
  const tooltipRef = useRef(null);

  const tooltipVariants = cva(
    ['relative flex flex-1', { 'cursor-pointer': content }],
    {
      variants: {
        alignment: {
          start: 'justify-start',
          center: 'justify-center',
          end: 'justify-end',
        },
      },
      defaultVariants: {
        alignment: 'center',
      },
    },
  );

  useEffect(() => {
    if (showTooltip && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      const newTop = rect.bottom + 8 + window.scrollY; // 8px offset below trigger
      let newLeft = rect.left + window.scrollX;
      setPosition({ top: newTop, left: newLeft });

      requestAnimationFrame(() => {
        if (tooltipRef.current) {
          const tooltipWidth = tooltipRef.current.offsetWidth;
          if (newLeft + tooltipWidth > window.innerWidth) {
            newLeft = window.innerWidth - tooltipWidth - 8;
            setPosition((prev) => ({
              ...prev,
              left: newLeft,
            }));
          }
        }
      });
    }
  }, [showTooltip]);

  return (
    <div className={cn(tooltipVariants({ alignment }))}>
      <div
        ref={triggerRef}
        onClick={() => setShowTooltip(!showTooltip)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {children}
      </div>
      {showTooltip &&
        ReactDOM.createPortal(
          <div
            ref={tooltipRef}
            className={cn(
              'absolute shadow w-max',
              'bg-raisin-black text-white rounded p-2',
              'pointer-events-none z-50',
              'text-xs font-semibold',
            )}
            style={{
              top: position.top,
              left: position.left,
            }}
          >
            {content}
          </div>,
          document.body,
        )}
    </div>
  );
};

export default Tooltip;
