import { queryBuilder } from 'common/utils';

import { apiSlice } from './api.slice';

export const offerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOffers: builder.query({
      query: ({
        page = 1,
        size = 50,
        orderBy = 'expires_at,-updated_at',
        status,
        with_owner = false,
        with_company = false,
      }) => ({
        url: queryBuilder('/offers/', {
          page,
          size,
          orderBy,
          status,
          with_owner,
          with_company,
        }),
      }),
      providesTags: ['Offer'],
    }),
    getOfferById: builder.query({
      query: ({ offerId }) => ({
        url: `/offers/${offerId}`,
        method: 'GET',
      }),
      providesTags: ['Offer'],
    }),
    getOfferBySlug: builder.query({
      query: ({ offerSlug, with_tests = true, with_questions = true }) => ({
        url: `/offers/${offerSlug}?with_tests=${with_tests}&with_questions=${with_questions}`,
        method: 'GET',
      }),
      providesTags: ['Offer'],
    }),
    getOfferAnalyticsBySlug: builder.query({
      query: ({ offerSlug }) => ({
        url: `/offers/${offerSlug}/analytics`,
        method: 'GET',
      }),
      providesTags: ['Analytics'],
    }),
    getOfferSubmissionsBySlug: builder.query({
      query: ({
        offerSlug,
        phase,
        isFavorite,
        orderBy,
        search,
        withDetail,
        data,
        size = 100,
        page,
      }) => {
        if (data) {
          return {
            url: queryBuilder(`/offers/${offerSlug}/submissions`, {
              phase,
              isFavorite,
              orderBy,
              search,
              withDetail,
              size,
              page,
            }),
            body: data,
            method: 'POST',
          };
        } else
          return {
            url: queryBuilder(`/offers/${offerSlug}/submissions`, {
              phase,
              isFavorite,
              orderBy,
              search,
              withDetail,
              size,
              page,
            }),
            method: 'GET',
          };
      },
      providesTags: ['Board'],
      serializeQueryArgs: ({ queryArgs }) => {
        const newQueryArgs = { ...queryArgs };
        if (newQueryArgs.page) {
          delete newQueryArgs.page;
        }
        return newQueryArgs;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.page !== previousArg?.page;
      },
      merge: (currentCacheData, responseData, meta) => {
        if (meta.arg.page > 1) {
          return {
            ...currentCacheData,
            ...responseData,
            items: [...currentCacheData.items, ...responseData.items],
          };
        }

        return responseData;
      },
    }),
    createOffer: builder.mutation({
      query: (data) => ({
        url: '/offers/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Offer']),
    }),
    updateOffer: builder.mutation({
      query: ({ offerSlug, requestBody }) => ({
        url: `/offers/${offerSlug}`,
        method: 'PATCH',
        body: requestBody,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Offer', 'Board']),
    }),
    updateOfferStatus: builder.mutation({
      query: ({ offerSlug, offerStatus }) => ({
        url: `/offers/${offerSlug}/status?offer_status=${offerStatus}`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Offer']),
    }),
    deleteOffer: builder.mutation({
      query: ({ offerId }) => ({
        url: `/offers/${offerId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error) =>
        error ? [] : ['Offer', 'Board', 'Submission'],
    }),
    syncOffer: builder.mutation({
      query: ({ offerSlug }) => ({
        url: `/offers/${offerSlug}/submissions/sync/workable`,
        method: 'PATCH',
      }),
    }),
    importSubmissions: builder.mutation({
      query: ({ offerId, formData }) => {
        return {
          url: `/offers/${offerId}/submissions/`,
          method: 'PATCH',
          body: formData,
          timeout: 120000,
        };
      },
      invalidatesTags: (result, error) =>
        error ? [] : ['Offer', 'Board', 'Submission', 'Analytics'],
    }),
    generateOfferDetails: builder.mutation({
      query: (data) => ({
        url: '/offers/suggestions/description',
        method: 'POST',
        body: data,
        timeout: 90000,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Offer']),
    }),
    generateOfferTests: builder.mutation({
      query: (data) => ({
        url: '/offers/suggestions/tests',
        method: 'POST',
        body: data,
        timeout: 200000,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Offer']),
    }),
    generateOfferQuestions: builder.mutation({
      query: (data) => ({
        url: '/offers/suggestions/questions',
        method: 'POST',
        body: data,
        timeout: 90000,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Offer']),
    }),
    getOfferChatBySlug: builder.query({
      query: ({ offerSlug }) => ({
        url: `/offers/${offerSlug}/chat`,
        method: 'GET',
        timeout: 90000,
      }),
    }),
    resetOfferAssistant: builder.mutation({
      query: ({ offerSlug }) => ({
        url: `/offers/${offerSlug}/ai/reset_assistant`,
        method: 'POST',
      }),
      invalidatesTags: (result, error) => (error ? [] : []),
    }),
    resetSubmissionsAIInfo: builder.mutation({
      query: ({ offerSlug }) => ({
        url: `/offers/${offerSlug}/ai/reset_submissions_ai_info`,
        method: 'POST',
      }),
      invalidatesTags: (result, error) => (error ? [] : []),
    }),
    setupExternalSource: builder.mutation({
      query: ({ offerSlug, externalSourceName, data }) => ({
        url: `/offers/${offerSlug}/external_source/${externalSourceName}/setup`,
        method: 'POST',
        body: data,
      }),
    }),
    syncExternalSource: builder.mutation({
      query: ({ offerSlug }) => {
        return {
          url: `/offers/${offerSlug}/external_source/sync/`,
          method: 'POST',
          timeout: 120000,
        };
      },
      invalidatesTags: (result, error) =>
        error ? [] : ['Offer', 'Board', 'Submission', 'Analytics'],
    }),
    getExternalSourceOffers: builder.mutation({
      query: ({ offerSlug }) => {
        return {
          url: `/offers/${offerSlug}/external_source/offers/`,
          method: 'GET',
          timeout: 120000,
        };
      },
    }),
  }),
});

export const {
  useGetOffersQuery,
  useGetOfferByIdQuery,
  useGetOfferBySlugQuery,
  useGetOfferAnalyticsBySlugQuery,
  useGetOfferSubmissionsBySlugQuery,
  useCreateOfferMutation,
  useUpdateOfferMutation,
  useUpdateOfferStatusMutation,
  useDeleteOfferMutation,
  useSyncOfferMutation,
  useImportSubmissionsMutation,
  useGenerateOfferDetailsMutation,
  useGenerateOfferTestsMutation,
  useGenerateOfferQuestionsMutation,
  useGetOfferChatBySlugQuery,
  useResetOfferAssistantMutation,
  useResetSubmissionsAIInfoMutation,
  useSetupExternalSourceMutation,
  useSyncExternalSourceMutation,
  useGetExternalSourceOffersMutation,
} = offerApiSlice;
